<ng-container *ngIf="media && media.Url">
  <ng-container *ngIf="media.Link && externalLink">
    <a
      [href]="media.Link"
      target="_blank"
      rel="noreferrer {{
        media.Link.includes('tradetracker.net') ? 'sponsored' : 'noopener'
      }}"
      attr.aria-label="{{ name }}"
    >
      <img
        class="1"
        attr.loading="{{ lazyLoad ? 'lazy' : 'eager' }}"
        *ngIf="media && media.Url"
        [src]="
          media.Url | apiImage : width : media : mode : height : fixedHeight
        "
        [alt]="name"
        [width]="width"
        [height]="height ? height : (media | imageSize : width)"
      />
    </a>
  </ng-container>
  <ng-container *ngIf="media.Link && !externalLink">
    <a [href]="media.Link" attr.aria-label="{{ name }}">
      <img
        class="2"
        attr.loading="{{ lazyLoad ? 'lazy' : 'eager' }}"
        *ngIf="media && media.Url"
        [src]="
          media.Url | apiImage : width : media : mode : height : fixedHeight
        "
        [alt]="name"
        [width]="width"
        [height]="height ? height : (media | imageSize : width)"
      />
    </a>
  </ng-container>
  <ng-container *ngIf="!media.Link">
    <img
      class="3"
      attr.loading="{{ lazyLoad ? 'lazy' : 'eager' }}"
      *ngIf="media && media.Url"
      [src]="media.Url | apiImage : width : media : mode : height : fixedHeight"
      [alt]="name"
      [width]="width"
      [height]="height ? height : (media | imageSize : width)"
    />
  </ng-container>
</ng-container>
