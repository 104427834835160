import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './material.module';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SvgIconsComponent } from './svg-icons/svg-icons.component';
import { ApiImagePipe } from './pipes/image.pipe';

import { BrowserStateInterceptor } from './interceptors/browser-state.interceptor';
import { TransferStateInterceptor } from './interceptors/transfer-state.interceptor';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { ImageSizePipe } from './pipes/image-size.pipe';
import { AnalyticsService } from './services/analytics.service';
import { TopBannerComponent } from './banner/top-banner/top-banner.component';
import { SharedModule } from './shared.module';

import { registerLocaleData } from '@angular/common';
import localeNo from '@angular/common/locales/no';
import { TravelmarketComponent } from './travelmarket/travelmarket.component';
import { ScriptLoaderService } from './services/script-loading.service';
registerLocaleData(localeNo);

export function getRequest() {
  return {};
}
export function getResponse() {
  return {};
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SvgIconsComponent,
    TopBannerComponent,
    TravelmarketComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TransferHttpCacheModule,
    SharedModule,
  ],
  providers: [
    ApiImagePipe,
    ImageSizePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
    { provide: 'req', useFactory: getRequest },
    { provide: 'res', useFactory: getResponse },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TransferStateInterceptor,
      multi: true,
    },
    AnalyticsService,
    { provide: LOCALE_ID, useValue: 'no-NB' },
    ScriptLoaderService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
