import { Component, Input, OnInit } from '@angular/core';

declare let postscribe: any;

@Component({
  selector: 'app-travelmarket',
  templateUrl: './travelmarket.component.html',
  styleUrls: ['./travelmarket.component.scss'],
})
export class TravelmarketComponent implements OnInit {
  @Input() fromDate?: string = '';
  @Input() toDate?: string = '';
  @Input() destrinationIATA?: string;

  constructor() {}

  ngOnInit(): void {
    postscribe(
      '#flightIframe',
      '<script type="text/javascript" src="https://partner.tmiweb.net/reiser_no/partnersetup1444.js"></script>'
    );
  }
}
