import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BannerModel } from './models/banner.model';
import { PageStatisticModel } from './models/page-statistic.model';
import { SettingsModel } from './models/settings.model';
import { AnalyticsService } from './services/analytics.service';
import { AppService } from './services/app.service';
import { BannerService } from './services/banner.service';
import { SettingsService } from './services/settings.service';
import { CookieService } from './services/cookie.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  banners: BannerModel[] = [];
  title = 'Reiser';

  data: SettingsModel = new SettingsModel();
  cookieConsentShow: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private appService: AppService,
    private settingsService: SettingsService,
    private analyticsService: AnalyticsService,
    private renderer2: Renderer2,
    private bannerService: BannerService,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.setStatistics();
      }
    });
  }

  ngOnInit(): void {
    this.appService
      .getData('settings')
      .then((x) => (this.data = JSON.parse(x)))
      .then(() => this.settingsService.changeSettings(this.data));

    this.bannerService.getBanners();
    this.bannerService.currentBanners.subscribe((x) => (this.banners = x));

    const cookieConsent = this.cookieService.getItem('cookie-consent');

    if (
      cookieConsent === null ||
      cookieConsent !== environment.cookieVersion.toString()
    ) {
      this.cookieConsentShow = true;
    }

    if (isPlatformBrowser(this.platformId)) {
      if (cookieConsent === environment.cookieVersion.toString()) {
        this.analyticsService.startTracking(this.renderer2);
      }
    }
  }

  setCookie() {
    var expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 365);

    this.cookieService.setItem(
      'cookie-consent',
      environment.cookieVersion.toString(),
      expireDate,
      '/'
    );
    localStorage.setItem(
      'cookie-consent',
      environment.cookieVersion.toString()
    );
    this.cookieConsentShow = false;
    this.analyticsService.startTracking(this.renderer2);
  }

  setStatistics() {
    if (isPlatformBrowser(this.platformId)) {
      var statistic = new PageStatisticModel();
      statistic.Url = this.router.url.split('?')[0];

      this.appService.postData('stat', JSON.stringify(statistic));
    }
  }
}
