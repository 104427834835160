import { ElementRef, Injectable, Renderer2 } from '@angular/core';
import { ReplaySubject } from 'rxjs';

declare const ga: any;
export interface AnalyticsEvent {
  type: 'PAGEVIEW' | 'EVENT';
  category?: string;
  action?: string;
  label: string;
}

@Injectable()
export class AnalyticsService {
  eventsQueue$ = new ReplaySubject<AnalyticsEvent>();

  constructor() {}

  public startTracking(renderer2: Renderer2): void {
    this.addTagManager(renderer2);
  }

  addTagManager(renderer2: Renderer2) {
    const googleTagManagerId = 'GTM-MBH5DX3';

    let headerScript = renderer2.createElement('script') as HTMLScriptElement;
    headerScript.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${googleTagManagerId}')`;

    var noscript = document.createElement('noscript');
    var iframe = document.createElement('iframe');
    iframe.setAttribute(
      'src',
      `https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`
    );
    iframe.setAttribute('height', '0');
    iframe.setAttribute('width', '0');
    iframe.setAttribute('style', 'display:none;visibility:hidden');
    noscript.appendChild(iframe);

    renderer2.appendChild(document.head, headerScript);
    renderer2.appendChild(document.body, noscript);
  }
}
