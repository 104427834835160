import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { Title } from '@angular/platform-browser';

import { MaterialModule } from './material.module';
import { PipeModule } from './pipe.module';
import { InnerHtmlComponent } from './inner-html/inner-html.component';
import { BlogListComponent } from './blog-list/blog-list.component';
import { HighlightDirective } from './directives/highlight.directive';
import { LocationListComponent } from './location-list/location-list.component';
import { LoaderComponent } from './loader/loader.component';
import { BlogListPostComponent } from './blog-list-post/blog-list-post.component';
import { IframesComponent } from './iframes/iframes.component';
import { FlightComponent } from './iframes/flight/flight.component';
import { CharterComponent } from './iframes/charter/charter.component';
import { RentalCarComponent } from './iframes/rental-car/rental-car.component';
import { SpareSeatComponent } from './iframes/spare-seat/spare-seat.component';
import { AnalyticsService } from './services/analytics.service';

import { NgImageSliderModule } from 'ng-image-slider';
import { SectionContentComponent } from './section-content/section-content.component';
import { SideBannerComponent } from './banner/side-banner/side-banner.component';

import { DatePipe } from '@angular/common';
import { ThemeListComponent } from './theme-list/theme-list.component';
import { HolidayrentalComponent } from './iframes/holidayrental/holidayrental.component';
import { MediaComponent } from './media/media.component';
import { TrivagoComponent } from './iframes/trivago/trivago.component';

@NgModule({
  declarations: [
    HighlightDirective,
    InnerHtmlComponent,
    BlogListComponent,
    LocationListComponent,
    LoaderComponent,
    BlogListPostComponent,
    IframesComponent,
    FlightComponent,
    CharterComponent,
    RentalCarComponent,
    SpareSeatComponent,
    SectionContentComponent,
    SideBannerComponent,
    ThemeListComponent,
    HolidayrentalComponent,
    MediaComponent,
    TrivagoComponent,
  ],
  imports: [
    PipeModule,
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgImageSliderModule,
  ],
  exports: [
    MaterialModule,
    FormsModule,
    InnerHtmlComponent,
    ReactiveFormsModule,
    PipeModule,
    BlogListComponent,
    LocationListComponent,
    LoaderComponent,
    BlogListPostComponent,
    IframesComponent,
    FlightComponent,
    CharterComponent,
    RentalCarComponent,
    SpareSeatComponent,
    NgImageSliderModule,
    SectionContentComponent,
    SideBannerComponent,
    ThemeListComponent,
    HolidayrentalComponent,
    MediaComponent,
    TrivagoComponent,
  ],
  providers: [DatePipe, Title, AnalyticsService],
})
export class SharedModule {}
