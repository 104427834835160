export class SettingsModel {
  FooterLinks?: FooterLinks[];
}

export class FooterLinks {
  Id?: number;
  SortOrder?: number;
  Name?: string;
  Url?: string;
}
