<div *ngIf="fileUrl" class="custom-image-main" [ngClass]="{'ng-fullimage-loading': imageLoading}">
    <img class="image"
        (load)="imageLoading = false"
        [ngClass]="{'ratio': ratio}"
        *ngIf="type === IMAGE && fileUrl"
        [src]="fileUrl"
        [alt]="alt"
        [title]="title"
        [attr.loading]="lazy == true ? 'lazy' : null">
    <iframe class="youtube"
        *ngIf="type === YOUTUBE && fileUrl"
        [src]="fileUrl"
        [attr.loading]="lazy == true ? 'lazy' : null"
        frameborder="0"
        allow="autoplay"
        allowfullscreen></iframe>
    <video class="video"
        [id]="'video_' + imageIndex"
        [ngClass]="{'ratio': ratio}"
        (click)="videoClickHandler($event)"
        [autoplay]="videoAutoPlay"
        *ngIf="type === VIDEO"
        type="video/mp4"
        [attr.controls]="showVideoControls ? showVideoControls : null"
        controlsList="nodownload">
        <source [src]="fileUrl" type="video/mp4">
        Your browser does not support the video tag.
    </video>
    <div [dir]="direction" *ngIf="!fileUrl" class="invalid-msg">Invalid file format</div>
    <span *ngIf="type === YOUTUBE || type === VIDEO || isVideo" class="youtube-icon"></span>
</div>