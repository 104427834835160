export class MediaModel {
  TypeId: number = 0;
  Url: string = '';
  ImageRatio: number = 0;
  PageType: number = 0;
  Caption: string = '';
  FixedImageWidth: number = 0;
  FixedImageHeight: number = 0;
  Link: string = '';
}
