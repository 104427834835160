import { MediaModel } from './media.model';

export class BannerModel {
  Id?: number;
  ExternalLink?: string;
  Type?: number;
  Name?: string;
  ShowAsDefault: boolean = false;
  Media?: MediaModel;
  Locations: LocationBanner[] = [];
  StaticPages: StaticPageBanner[] = [];
  AdsenseId: number = 0;
}

export class LocationBanner {
  LocationId: number = 0;
}

export class StaticPageBanner {
  StaticPageId: number = 0;
}
