import { Component, OnInit } from '@angular/core';
import { FooterLinks } from '../models/settings.model';
import { SettingsService } from '../services/settings.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  data: FooterLinks[] = [];
  currentYear = new Date().getFullYear();

  constructor(private settingsService: SettingsService) {}

  ngOnInit(): void {
    this.settingsService.currentSettings.subscribe(
      (x) => (this.data = x.FooterLinks ?? [])
    );
  }
}
