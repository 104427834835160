import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-icons',
  templateUrl: './svg-icons.component.html',
})
export class SvgIconsComponent {
  constructor() {}

  ngOnInit(): void {}
}
