import { Component, OnInit } from '@angular/core';
import { BannerModel } from 'src/app/models/banner.model';
import { BannerService } from 'src/app/services/banner.service';

@Component({
  selector: 'app-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss'],
})
export class TopBannerComponent implements OnInit {
  banner: BannerModel = new BannerModel();
  banners: BannerModel[] = [];
  filteredBanner: BannerModel = new BannerModel();
  locationId: number = 0;
  countryId: number = 0;
  staticPageId: number = 0;

  loading = true;
  constructor(private bannerService: BannerService) {}

  ngOnInit(): void {
    this.bannerService.currentBanners.subscribe((x) => {
      this.banners = x;
      this.setTopBanner();
    });

    this.bannerService.currentBannerLocation.subscribe((x) => {
      this.locationId = x.LocationId;
      this.countryId = x.CountryId;
      this.setTopBanner();
    });

    this.bannerService.currentStaticPageId.subscribe((x) => {
      this.staticPageId = x;
      this.setTopBanner();
    });
  }

  setTopBanner() {
    this.loading = true;
    let banners = this.banners.filter((x) => x.Type == 2);

    if (this.staticPageId !== 0) {
      this.filteredBanner =
        banners.find((x) => {
          const index = x.StaticPages.findIndex(
            (t) => t.StaticPageId === this.staticPageId
          );
          if (index > -1) {
            return true;
          }
          return false;
        }) ?? new BannerModel();
    } else {
      if (this.locationId === 0) {
        this.filteredBanner =
          banners.find((x) => x.ShowAsDefault) ?? new BannerModel();
      } else {
        banners = banners.filter((locations) => {
          if (
            // @ts-ignore: Unreachable code error
            locations.Locations.filter((location) => {
              if (
                location.LocationId == this.locationId ||
                location.LocationId == this.countryId
              ) {
                return location;
              }
            }).length > 0
          ) {
            return locations;
          }
          return null;
        });

        if (banners && banners.length > 0) {
          this.filteredBanner = banners[0];
        } else {
          this.filteredBanner = new BannerModel();
        }
      }
    }

    this.banner = this.filteredBanner;

    this.loading = false;
  }

  bannerClick() {
    this.bannerService.bannerClick(this.banner?.Id ?? 0, this.locationId);
  }
}
