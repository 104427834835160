import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { BannerLocationModel } from '../models/banner-location.model';
import { BannerStatisticModel } from '../models/banner-statistic.model';
import { BannerModel } from '../models/banner.model';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  private banners = new BehaviorSubject<BannerModel[]>([]);
  currentBanners = this.banners.asObservable();

  private locationId = new BehaviorSubject<number>(0);
  currentLocationId = this.locationId.asObservable();

  private countryId = new BehaviorSubject<number>(0);
  currentCountryCode = this.countryId.asObservable();

  private staticPageId = new BehaviorSubject<number>(0);
  currentStaticPageId = this.staticPageId.asObservable();

  private bannerLocation = new BehaviorSubject<BannerLocationModel>(
    new BannerLocationModel()
  );
  currentBannerLocation = this.bannerLocation.asObservable();

  constructor(private appService: AppService) {}

  updateBanners(banners: BannerModel[]) {
    this.banners.next(banners);
  }

  updateLocation(id: number) {
    this.locationId.next(id);
  }

  updateCountryCode(id: number) {
    this.countryId.next(id);
  }

  updateBannerLocation(locationId: number, countryId: number) {
    const newBannerLocation = new BannerLocationModel();
    newBannerLocation.CountryId = countryId;
    newBannerLocation.LocationId = locationId;

    this.locationId.next(locationId);
    this.countryId.next(countryId);

    this.bannerLocation.next(newBannerLocation);
  }

  updateBannerStaticPage(staticPageId: number) {
    this.staticPageId.next(staticPageId);
  }

  getBanners() {
    this.appService
      .getData(`banners`)
      .then((x) => this.banners.next(JSON.parse(x)));
  }

  bannerClick(bannerId: number, locationId: number) {
    var bannerStatistics = new BannerStatisticModel();
    bannerStatistics.BannerId = bannerId;
    bannerStatistics.LocationId = locationId;

    this.appService.postData(
      'banner/statistic',
      JSON.stringify(bannerStatistics)
    );
  }
}
