import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(private httpClient: HttpClient) {}

  getData(url: string): Promise<any> {
    const getUrl = environment.apiDomain + url;

    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
    };

    return this.httpClient
      .get<any>(getUrl, { headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  postData(url: string, body: string, external: boolean = false): Promise<any> {
    const postDomain = !external ? environment.apiDomain : '';
    let postUrl = postDomain + url;

    postUrl = postUrl.replace(/\/+$/, '');
    const headers = {
      'content-type': 'application/json',
    };

    return this.httpClient
      .post<any>(postUrl, body, { headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  private extractData(res: Response) {
    const body = JSON.stringify(res);
    return body || {};
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
