import { isPlatformBrowser } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BrowserStateInterceptor implements HttpInterceptor {
  constructor(
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const key = makeStateKey(req.url);
    if (req.method === 'GET') {
      const storedResponse = this.transferState.get(key, null);
      const expired = this.hasExpired();
      if (storedResponse != null) {
        if (!expired) {
          this.setLocalStorageDate();
          const response = new HttpResponse({
            body: storedResponse,
            status: 200,
          });
        }
      }
    }

    return next.handle(req).pipe(
      tap((event: any) => {
        this.setLocalStorageDate();
        this.transferState.set(key, event.body);
      })
    );
  }

  hasExpired() {
    if (isPlatformBrowser(this.platformId)) {
      const transferstateexpires = localStorage.getItem('transferstateexpires');
      if (transferstateexpires) {
        const expires = new Date(JSON.parse(transferstateexpires));
        const dt = new Date();
        dt.setSeconds(dt.getSeconds() + 10);

        const expireDiff = +dt - +expires;
        if (expireDiff > 6000) {
          return true;
        }
      }
    }

    return false;
  }

  setLocalStorageDate() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('transferstateexpires', JSON.stringify(new Date()));
    }
  }
}
