import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { AppService } from './app.service';
import { isPlatformServer } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { SettingsModel } from '../models/settings.model';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private settings = new BehaviorSubject<SettingsModel>(new SettingsModel());
  currentSettings = this.settings.asObservable();

  private showSearchBox = new BehaviorSubject<boolean>(false);
  currentShowSearchBox = this.showSearchBox.asObservable();

  private loading = new BehaviorSubject<boolean>(true);
  currentLoading = this.loading.asObservable();

  private serverMobile = false;
  private serverTablet = false;

  constructor(
    private appService: AppService,
    @Optional() @Inject('mobile') protected mobile: boolean,
    @Optional() @Inject('tablet') protected tablet: boolean,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.serverMobile = mobile;
    this.serverTablet = tablet;
  }

  changeSettings(input: SettingsModel) {
    this.settings.next(input);
  }

  changeShowSearchBox(input: boolean) {
    this.showSearchBox.next(input);
  }

  changeLoading(input: boolean) {
    this.loading.next(input);
  }

  async getSettings(): Promise<any> {
    return this.appService.getData('settings');
  }

  getMobileStatus() {
    if (isPlatformServer(this.platformId)) {
      return this.serverMobile;
    }

    const ua = navigator.userAgent;
    if (
      /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      )
    ) {
      return true;
    }
    return false;
  }

  getTabletStatus() {
    if (isPlatformServer(this.platformId)) {
      return this.serverTablet;
    }
    const ua = navigator.userAgent;

    if (ua.match(/Tablet|iPad|iPod/i)) {
      return true;
    }

    return false;
  }

  getImageType(url: string) {
    const isImage = url.match(/.(jpg|jpeg|png|webp|gif)$/i);
    if (isImage) {
      return true;
    }

    return false;
  }

  getVideoType(url: string) {
    url = url.toLowerCase();

    if (url.includes('.mp4')) {
      return 'video/mp4';
    }

    if (url.includes('.mov')) {
      return 'video/mov';
    }

    if (url.includes('.wmv')) {
      return 'video/wmv';
    }

    if (url.includes('.flv')) {
      return 'video/flv';
    }

    if (url.includes('.avi')) {
      return 'video/avi';
    }

    if (url.includes('.avchd')) {
      return 'video/avchd';
    }

    if (url.includes('.webm')) {
      return 'video/webm';
    }

    return 'video/mkv';
  }
}
