export const environment = {
  production: true,
  apiDomain: 'https://api.reiser.no/',
  useImageFx: true,
  imgFxDomain: 'https://reiser.imgfx.net/{mode}/size-{size}.ll-0/',
  staticContent: 'C:/@Websites/StaticResources/Reiser.API',
  cookieVersion: 1,
  googleAnlayticsTrackingCode: 'UA-90669-1',
  adsense: {
    adClient: 'ca-pub-2521887182785967',
    show: true,
  },
};
