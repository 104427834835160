<div id="header-menu">
  <a [routerLink]="['/']" aria-label="Link til startsiden" id="logo-wrapper">
    <svg
      id="icon"
      aria-hidden="true"
      role="img"
      focusable="false"
      preserveAspectRatio="xMaxYMid"
      viewBox="0 0 1208.7 218.6"
    >
      <title>Lenke til startsiden</title>
      <use xlink:href="#icon-full"></use>
    </svg>
  </a>
  <nav class="navbar" [ngClass]="{ 'show-menu': showMenu }">
    <div>
      <a mat-button (click)="hideMenu()" mat-button [routerLink]="['fly']">
        Fly
      </a>
      <a mat-button (click)="hideMenu()" mat-button [routerLink]="['hotell']">
        Hotell
      </a>
      <a mat-button (click)="hideMenu()" mat-button [routerLink]="['charter']">
        Charter
      </a>
      <a
        mat-button
        (click)="hideMenu()"
        mat-button
        [routerLink]="['restplass']"
      >
        Restplass
      </a>
      <a mat-button (click)="hideMenu()" mat-button [routerLink]="['leiebil']">
        Leiebil
      </a>
      <a mat-button (click)="hideMenu()" mat-button [routerLink]="['feriehus']">
        Feriehus
      </a>
      <a mat-button (click)="hideMenu()" mat-button [routerLink]="['temaer']">
        Temaer
      </a>
      <a (click)="hideMenu()" mat-button [routerLink]="['reiseblogg']"
        >Reiseblogg</a
      >
      <a
        (click)="hideMenu()"
        mat-button
        [routerLink]="['temaer/populaere-reisemal']"
        >Populære Reisemål</a
      >
    </div>
  </nav>
  <button
    [ngClass]="{ open: showMenu }"
    id="nav-icon"
    type="button"
    aria-label="Vis menyen"
    (click)="showMenuToggle()"
  >
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</div>
