import { Inject, Optional, Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MediaModel } from '../models/media.model';
import { SettingsService } from '../services/settings.service';

@Pipe({ name: 'apiImage' })
export class ApiImagePipe implements PipeTransform {
  apiDomain = environment.apiDomain;
  isMobile = false;

  constructor(
    private settingsService: SettingsService,
    @Optional() @Inject('mobile') protected mobile: boolean
  ) {
    this.isMobile = mobile;
  }

  transform(
    imageUrl: string,
    imageWidth: number,
    image: MediaModel,
    mode: string = 'fill',
    imageHeight: number = 0,
    fixedHeight = false
  ) {
    // console.log('imageUrl :>> ', imageUrl);
    // console.log('imageWidth :>> ', imageWidth);
    // console.log('image :>> ', image);
    // console.log('mode :>> ', mode);
    // console.log('imageHeight :>> ', imageHeight);
    this.isMobile = this.settingsService.getMobileStatus();
    const imageType = image.PageType;

    if (!imageUrl) {
      return;
    }

    if (imageUrl.substring(0, 4) === 'http') {
      return imageUrl;
    }

    imageUrl = this.imgFxPath(imageType) + imageUrl;

    // console.log('imageType :>> ', imageType);
    // console.log('imageUrl :>> ', imageUrl);
    // console.log('image.ImageRatio :>> ', image.ImageRatio);
    if (this.isMobile) {
      if (imageWidth > 720) {
        imageWidth = 720;
      }
    }

    let size = '';
    if (imageWidth !== 0 && image.ImageRatio > 0) {
      const imagePath =
        environment.imgFxDomain.replace('{mode}', mode + '.webp') + imageUrl;
      if (imageHeight > 0) {
        size = imageWidth + 'x' + imageHeight;
        return imagePath.replace('{size}', size);
      }
      if (fixedHeight) {
        size = '1200x630';
        return imagePath.replace('{size}', size);
      }
      size = imageWidth + 'x' + Math.floor(imageWidth * image.ImageRatio);
      return imagePath.replace('{size}', size);
    }

    const imagePath =
      this.apiDomain +
      'media/' +
      mode +
      '/' +
      imageWidth +
      '/' +
      imageHeight +
      '/' +
      imageUrl.replace('upload/', '');

    return imagePath;
  }

  imgFxPath(imageType: number) {
    if (environment.useImageFx) {
      switch (imageType) {
        case 1:
          return 'destinasjon/';
        case 2:
          return 'blog/';
        case 3:
          return 'informasjon/';
        case 4:
          return 'destinasjon/';
        case 5:
          return 'destinasjon/';
        case 6:
          return 'blog/';
        case 7:
          return 'tema/';
        case 8:
          return 'tema/';
        case 9:
          return 'banner/';
        case 10:
          return 'tema/';
        case 11:
          return 'startside/';
        case 12:
          return 'informasjon/';
        case 13:
          return 'informasjon/';
        default:
          return 'editor/';
      }
    }
    switch (imageType) {
      case 1:
        return 'destinasjon/';
      case 2:
        return 'blog/';
      case 3:
        return 'informasjon/';
      case 4:
        return 'destinasjon/';
      case 5:
        return 'destinasjon/';
      case 6:
        return 'blog/';
      case 7:
        return 'tema/';
      case 8:
        return 'tema/';
      case 9:
        return 'banner/';
      case 10:
        return 'tema/';
      case 11:
        return 'editor/';
      case 12:
        return 'informasjon/';
      case 13:
        return 'informasjon/';
      default:
        return 'editor/';
    }
  }

  extractUrlValue(url: string, key: string) {
    var match = url.match(`${key}=([^&]+)`);
    return match ? match[1] : null;
  }
}
