import { NgModule } from '@angular/core';
import { ImageSizePipe } from './pipes/image-size.pipe';
import { ApiImagePipe } from './pipes/image.pipe';
import { SanitizeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeIframePipe } from './pipes/safe-iframe.pipe';

@NgModule({
  declarations: [ApiImagePipe, SanitizeHtmlPipe, SafeIframePipe, ImageSizePipe],
  imports: [],
  exports: [ApiImagePipe, SanitizeHtmlPipe, SafeIframePipe, ImageSizePipe],
})
export class PipeModule {}
