import { Inject, Injectable, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  constructor(
    @Inject(LOCALE_ID) locale: any,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    super(locale, platformId);
  }
  override getFirstDayOfWeek() {
    return 1;
  }
}
