<footer>
  <div class="logo">
    <svg
      id="icon"
      aria-hidden="true"
      role="img"
      focusable="false"
      preserveAspectRatio="xMaxYMid"
      viewBox="0 0 1208.7 218.6"
    >
      <title>Lenke til startsiden</title>
      <use xlink:href="#icon-footer"></use>
    </svg>
  </div>
  <div id="about">
    <ul>
      <li>Reiser.no AS</li>
      <li>Org.nr. 929 573 579</li>
      <li>Flåhagvegen 9</li>
      <li>2387 Brumunddal</li>
      <li>Epost: info@reiser.no</li>
    </ul>
  </div>
  <div id="links">
    <ul>
      <li>
        <a
          [routerLink]="['/annonsere-her']"
          title="Les om hur dere kan annonsere hos oss"
          >Annonsere</a
        >
      </li>
      <li>
        <a [routerLink]="['/om-oss']" title="Les mer om oss">Om oss</a>
      </li>
      <li>
        <a
          [routerLink]="['/personvernpolitikk']"
          title="Les mer om våre personvernpolitikk"
          >Personvern</a
        >
      </li>
      <li>
        <a [routerLink]="['/cookies']" title="Les mer om cookies">Cookies</a>
      </li>
    </ul>
  </div>
  <div id="text">
    <p>
      Reiser.no gir deg god informasjon om de beste reisemålene of tilbyr
      bookingløsninger med de beste prisene<br />
      Våre reiseguider er levert av
      <a href="https://www.arrivalguides.com/" target="_blank"
        >arrivalguides.com</a
      >
    </p>
  </div>
  <div id="social">
    <a
      mat-button
      href="https://www.facebook.com/reiserno"
      title="Følg oss på Facebook"
      target="_blank"
    >
      <svg
        id="footer-facebook"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
      >
        <use xlink:href="#brands-facebook"></use>
      </svg>
    </a>
    <a
      mat-button
      href="https://www.instagram.com/reiserno"
      title="Følg oss på Instagram"
      target="_blank"
    >
      <svg
        id="footer-facebook"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
      >
        <use xlink:href="#brands-instagram"></use>
      </svg>
    </a>
    <a
      mat-button
      href="https://twitter.com/reiserno"
      title="Følg oss på Twitter"
      target="_blank"
    >
      <svg
        id="footer-twitter"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
      >
        <use xlink:href="#brands-twitter"></use>
      </svg>
    </a>
    <a
      mat-button
      href="https://www.linkedin.com/company/reiser-no/"
      title="Følg oss på Linkedin"
      target="_blank"
    >
      <svg
        id="footer-linkedin"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
      >
        <use xlink:href="#brands-linkedin"></use>
      </svg>
    </a>
    <a
      mat-button
      href="https://www.pinterest.se/reidarsyversen"
      title="Følg oss på Linkedin"
      target="_blank"
    >
      <svg
        id="footer-pinterest"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
      >
        <use xlink:href="#brands-pinterest"></use>
      </svg>
    </a>
  </div>
</footer>
<div id="copyright">@ Reiser.no AS {{ currentYear }}</div>
