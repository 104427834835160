import { Component, Input, OnInit } from '@angular/core';
import { MediaModel } from '../models/media.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
})
export class MediaComponent implements OnInit {
  @Input() media: MediaModel = new MediaModel();
  @Input() name?: string = '';
  @Input() width: number = 0;
  @Input() height?: number;
  @Input() fixedHeight: boolean = false;
  @Input() mode: string = 'fit';
  @Input() lazyLoad: boolean = true;

  externalLink: boolean = false;

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {
    if (this.media.Link) {
      let searchedDate = new Date();
      const newSearchedDate = new Date(
        searchedDate.setDate(searchedDate.getDate() + 7)
      );

      this.media.Link = this.replaceAll(
        this.media.Link,
        '{date}',
        this.datePipe.transform(newSearchedDate, 'yyyy-MM-dd')?.toString() ?? ''
      );
      this.media.Link = this.media.Link.replace('http://', 'https://');
    }

    if (this.media.Link?.substring(0, 4) === 'http') {
      this.externalLink = true;
    }
  }

  replaceAll(html: string, find: string, replace: string) {
    return html.replace(new RegExp(find, 'g'), replace);
  }
}
