<app-top-banner></app-top-banner>
<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
<app-svg-icons></app-svg-icons>
<div id="cookie-consent" *ngIf="cookieConsentShow">
  <div>
    Vi bruker informasjonskapsler og deler informasjonen din med tredjeparter
    som tilpasser annonsering, analyserer trafikk og leverer tjenester for
    sosiale medier.
    <a [routerLink]="['/cookies']">Se mer informasjon</a>
    <button (click)="setCookie()" mat-stroked-button color="primary">OK</button>
  </div>
</div>
