import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  showMenu = false;
  constructor() {}

  ngOnInit(): void {}

  showMenuToggle() {
    this.showMenu = !this.showMenu;
    if (this.showMenu) {
      document.querySelector('body')?.classList.add('modal-open');
    } else {
      document.querySelector('body')?.classList.remove('modal-open');
    }
  }

  hideMenu() {
    this.showMenu = false;
    document.querySelector('body')?.classList.remove('modal-open');
  }
}
