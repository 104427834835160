import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TravelmarketComponent } from './travelmarket/travelmarket.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'annonsere-her',
    loadChildren: () =>
      import('./static-page/static-page.module').then(
        (m) => m.StaticPageModule
      ),
  },
  {
    path: 'kontakt-oss',
    loadChildren: () =>
      import('./static-page/static-page.module').then(
        (m) => m.StaticPageModule
      ),
  },
  {
    path: 'om-oss',
    loadChildren: () =>
      import('./static-page/static-page.module').then(
        (m) => m.StaticPageModule
      ),
  },
  {
    path: 'personvernpolitikk',
    loadChildren: () =>
      import('./static-page/static-page.module').then(
        (m) => m.StaticPageModule
      ),
  },
  {
    path: 'cookies',
    loadChildren: () =>
      import('./static-page/static-page.module').then(
        (m) => m.StaticPageModule
      ),
  },
  {
    path: 'fly',
    loadChildren: () =>
      import('./static-page/static-page.module').then(
        (m) => m.StaticPageModule
      ),
  },
  {
    path: 'hotell',
    loadChildren: () =>
      import('./static-page/static-page.module').then(
        (m) => m.StaticPageModule
      ),
  },
  {
    path: 'restplass',
    loadChildren: () =>
      import('./static-page/static-page.module').then(
        (m) => m.StaticPageModule
      ),
  },
  {
    path: 'charter',
    loadChildren: () =>
      import('./static-page/static-page.module').then(
        (m) => m.StaticPageModule
      ),
  },
  {
    path: 'leiebil',
    loadChildren: () =>
      import('./static-page/static-page.module').then(
        (m) => m.StaticPageModule
      ),
  },
  {
    path: 'feriehus',
    loadChildren: () =>
      import('./static-page/static-page.module').then(
        (m) => m.StaticPageModule
      ),
  },
  {
    path: 'booking',
    loadChildren: () =>
      import('./static-page/static-page.module').then(
        (m) => m.StaticPageModule
      ),
  },
  {
    path: 'reiseblogg',
    loadChildren: () => import('./blog/blog.module').then((m) => m.BlogModule),
  },
  {
    path: 'reiseblogg/:url',
    loadChildren: () =>
      import('./blog-post/blog-post.module').then((m) => m.BlogPostModule),
  },
  {
    path: 'temaer',
    loadChildren: () =>
      import('./theme/theme.module').then((m) => m.ThemeModule),
  },
  {
    path: 'travelmarket',
    component: TravelmarketComponent,
  },
  {
    path: 'temaer/:url',
    loadChildren: () =>
      import('./theme-post/theme-post.module').then((m) => m.ThemePostModule),
  },
  {
    path: 'flyresultater',
    loadChildren: () =>
      import('./iframeresult/iframeresult.module').then(
        (m) => m.IframResultModule
      ),
  },
  {
    path: 'charterresultater/hotell',
    loadChildren: () =>
      import('./iframeresult/iframeresult.module').then(
        (m) => m.IframResultModule
      ),
  },
  {
    path: 'charterresultater',
    loadChildren: () =>
      import('./iframeresult/iframeresult.module').then(
        (m) => m.IframResultModule
      ),
  },
  {
    path: 'tilbud',
    loadChildren: () =>
      import('./static-page/static-page.module').then(
        (m) => m.StaticPageModule
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./location/location.module').then((m) => m.LocationModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      onSameUrlNavigation: 'reload',
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
