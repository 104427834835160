<ng-container
  *ngIf="
    filteredBanner && filteredBanner.Media && filteredBanner.AdsenseId === 0
  "
>
  <a
    *ngIf="
      filteredBanner.Media &&
      filteredBanner.Media.Url &&
      filteredBanner.ExternalLink
    "
    [href]="filteredBanner.ExternalLink"
    target="_blank"
    rel="noopener noreferrer"
    attr.aria-label="{{ banner.Name }}"
    (click)="bannerClick()"
  >
    <app-media
      *ngIf="filteredBanner.Media && filteredBanner.Media.Url"
      [media]="filteredBanner.Media"
      [width]="1200"
      [mode]="'fill'"
      [name]="filteredBanner.Name ?? ''"
      [lazyLoad]="false"
    ></app-media>
  </a>
</ng-container>
