import { Pipe, PipeTransform } from '@angular/core';
import { MediaModel } from '../models/media.model';

@Pipe({ name: 'imageSize' })
export class ImageSizePipe implements PipeTransform {
  constructor() {}

  transform(
    image: MediaModel,
    imageWidth: number = 0,
    imageHeight: number = 0
  ) {
    if (image.ImageRatio && imageWidth) {
      return Math.floor(imageWidth * image.ImageRatio);
    }
    return Math.floor(imageHeight / image.ImageRatio);
  }
}
